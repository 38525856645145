import { RouterBreadcrumb } from '@/lib/types/RouterBreadcrumb.type';
import { RouteConfig } from 'vue-router/types/router';

const config: Array<RouteConfig> = [
  {
    path: 'brands-list',
    component: () => import(/* webpackChunkName: "Brands" */ '@/views/Dashboard/Brands/BrandsList.vue'),
    meta: {
      requiresAuth: true,
      breadcrumbs: [{
        text: 'Brands',
        to: { name: 'brands-list' },
      }] as RouterBreadcrumb[],
    },
    children: [
      {
        path: '',
        redirect: 'list',
      },
      {
        path: 'list',
        name: 'brands-list',
        component: () => import(/* webpackChunkName: "Brands" */ '@/views/Dashboard/Brands/BrandsList.vue'),
        meta: {
          title: 'List Brands',
          subtitle: 'Browse through all the accessible brand records that exist within our system.',
        },
      },
      {
        path: ':id',
        name: 'brands-view',
        component: () => import(/* webpackChunkName: "Brands" */ '@/views/Dashboard/Brands/BrandsView.vue'),
        props: (route) => ({
          id: route.params.id,
        }),
        beforeEnter: (to, _from, next) => {
          if (to.params.id) return next();
          throw Error('Missing `id` parameter');
        },
        meta: {
          title: 'View Brand',
          subtitle: 'Review the details and data associated with this brand\'s record.',
          breadcrumbs(this: Vue): RouterBreadcrumb[] {
            return [{
              text: this.$route.params.id,
              to: { name: 'brands-view', params: { id: this.$route.params.id } },
            }];
          },
        },
      },
      {
        path: ':id/edit',
        name: 'brands-edit',
        component: () => import(/* webpackChunkName: "Brands" */ '@/views/Dashboard/Brands/BrandsEdit.vue'),
        props: (route) => ({
          id: route.params.id,
        }),
        beforeEnter: (to, _from, next) => {
          if (to.params.id) return next();
          throw Error('Missing `id` parameter');
        },
        meta: {
          title: 'Edit Brand',
          subtitle: 'Modify the details and data associated with this brand\'s record.',
          breadcrumbs(this: Vue): RouterBreadcrumb[] {
            return [{
              text: 'Edit',
              to: { name: 'brands-edit', params: { id: this.$route.params.id } },
            }];
          },
        },
      },
    ],
  },
];

export default config;
