




































































































































































































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Loading } from '@/lib/types';
import { AssignedSite, User } from '@/models/internal';
import { UserService } from '@/services';
import { DataTableHeader } from 'vuetify';
import { ITableView, PaginationOptions } from '@/lib/interfaces';
import { Logger } from '@/tools/Logger';
import { Utility } from '@/tools/Utility';
import { debounce } from 'lodash';
import UsersListControls from './components/UsersListControls.vue';
import UsersListDetails from './components/UsersListDetails.vue';
import FileSaver from 'file-saver';
import moment from 'moment';
import ContextBarManager from '@/components/ContextBar/classes/ContextBarManager';
import UsersCreateDialog from '../components/UsersCreateDialog.vue';
import { UsersListRole } from './components/UsersListRole.vue';

@Component({
  name: 'UsersList',
  components: {
    UsersListControls,
    UsersListDetails,
    UsersListRole,
  },
})
export default class UsersList extends Vue implements ITableView<User> {
  public data: User[] = [];

  public selected: User[] = [];

  public headers: DataTableHeader[] = [
    { text: 'Name', value: 'name' },
    { text: 'Email', value: 'email' },
    { text: 'Created At', value: 'created_at' },
    { text: 'Roles', value: 'roles' },
    { text: 'Invited', value: 'invited', sortable: false },
    { text: 'Confirmed', value: 'confirmed', sortable: false },
    { text: 'Locked', value: 'access_locked', sortable: false },
  ];

  /**
   * Debounced init function (used for limiting fetches from the search field)
   */
  public debouncedInit = debounce(this.init, 500);

  protected highlightSearchText = Utility.highlightSearchText;

  protected formatDate = Utility.formatDate;

  protected options: PaginationOptions = {
    page: 1,
    itemsPerPage: 10,
    sortBy: ['name'],
    sortDesc: [false],
  };

  protected actions = [
    {
      label: 'Download CSV',
      value: 'download_csv',
      icon: 'mdi-file-delimited-outline',
    },
  ];

  private readonly logger: Logger = new Logger({ context: 'UsersList' });

  private readonly userService: UserService = UserService.getInstance();

  private readonly loading: Loading = {
    table: false,
  };

  private readonly titleCase = Utility.titleCase;

  private search = '';

  private showLocked = false;

  private totalRecords: number | string = -1;

  @Watch('options', { deep: true, immediate: false })
  protected watchPagination() {
    this.loading.table = true;
    this.init();
  }

  @Watch('search')
  protected watchSearch() {
    this.loading.table = true;
    this.selected = [];
    this.options.page = 1;
    this.debouncedInit();
  }

  @Watch('showLocked')
  protected watchShowLocked() {
    this.loading.table = true;
    this.selected = [];
    this.options.page = 1;
    this.debouncedInit();
  }

  public async init() {
    this.loading.table = true;
    try {
      const users = await this.fetchData();
      this.data = users;
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.loading.table = false;
    }
  }

  public created() {
    this.init();
    this.updateContextBar();
  }

  public async fetchData() {
    const sortBy =
      this.options.sortBy.reduce((acc, curr, idx, arr) => {
        const sortDirection = !this.options.sortDesc[idx] ? 'asc' : 'desc';
        const sortString = acc + `${curr}:${sortDirection}`;
        return idx < arr.length - 1 ? sortString + ',' : sortString;
      }, '') || undefined;

    const params: any = {
      authentication_token: this.userService.getActiveToken(),
      show_details: true,
    };

    if (this.options.itemsPerPage === -1) {
      params.page = 1;
      params.per_page = this.totalRecords;
    } else {
      params.page = this.options.page;
      params.per_page = this.options.itemsPerPage;
    }

    if (sortBy) params.sort = sortBy;

    if (this.search) params.search = this.search;

    if (this.showLocked) params.show_locked = this.showLocked;

    const { order_form_users, total } = await this.userService.api.find(params);

    if (!order_form_users) throw Error('Unable to fetch a list of Orders');

    this.totalRecords = total;

    return order_form_users;
  }

  protected updateContextBar() {
    ContextBarManager.setActions(
      {
        icon: 'mdi-account-multiple-plus-outline',
        color: 'success',
        label: 'Invite User',
        callback: UsersCreateDialog.attach().bind(this),
      },
      {
        icon: 'mdi-file-delimited-outline',
        color: 'primary',
        label: 'Download CSV',
        callback: this.downloadCsv,
      },
    );
  }

  protected async onClickLock(user: User) {
    return this.lockUser(user);
  }

  protected async onClickUnlock(user: User) {
    return this.unlockUser(user);
  }

  protected async userAddDistChannel(user: User, distChannel: string) {
    try {
      const updatedUser = await this.userService.api.addDistChannel({
        id: user.id,
        authentication_token: this.userService.getActiveToken(),
        dist_channel: distChannel,
      });
      if (updatedUser) {
        user.dist_channels = updatedUser.dist_channels;
      }
      this.$genify.notify(
        `Distribution channel "${distChannel}" has been added!`,
        'success',
      );
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert(
        'There was an error adding the distribution channel.',
        'error',
      );
    }
  }

  protected async userRemoveDistChannel(user: User, distChannel: string) {
    try {
      const updatedUser = await this.userService.api.removeDistChannel({
        id: user.id,
        authentication_token: this.userService.getActiveToken(),
        dist_channel: distChannel,
      });
      if (updatedUser) {
        user.dist_channels = updatedUser.dist_channels;
      }
      this.$genify.notify(
        `Distribution channel "${distChannel}" has been removed!`,
        'success',
      );
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert(
        'There was an error removing the distribution channel.',
        'error',
      );
    }
  }

  protected async userAddSiteRequest(user: User, site: AssignedSite) {
    try {
      const updatedUser = await this.userService.api.addSiteRequest({
        id: user.id,
        authentication_token: this.userService.getActiveToken(),
        site_id: site.id,
      });
      if (updatedUser) {
        user.assigned_sites = updatedUser.assigned_sites;
      }
      this.$genify.notify(`Site "${site.name}" has been added!`, 'success');
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert('There was an error adding the site.', 'error');
    }
  }

  protected async userRemoveSiteRequest(user: User, site: AssignedSite) {
    try {
      const updatedUser = await this.userService.api.removeSiteRequest({
        id: user.id,
        authentication_token: this.userService.getActiveToken(),
        site_id: site.id,
      });
      if (updatedUser) {
        user.assigned_sites = updatedUser.assigned_sites;
      }
      this.$genify.notify(`Site "${site.name}" has been removed!`, 'success');
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert('There was an error removing the site.', 'error');
    }
  }

  protected async userAddRole(user: User, role: string, site?: AssignedSite) {
    try {
      const updatedUser = await this.userService.api.addRole({
        id: user.id,
        authentication_token: this.userService.getActiveToken(),
        role,
        site_id: site?.id,
      });
      if (updatedUser) {
        user.roles = updatedUser.roles;
        user.assigned_sites = updatedUser.assigned_sites;
      }
      if (site) {
        this.$genify.notify(
          `${user.name} has been made "${this.titleCase(role)}" for "${site.name}"!`,
          'success',
        );
      } else {
        this.$genify.notify(
          `Role "${this.titleCase(role)}" has been added!`,
          'success',
        );
      }
      return user;
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert('There was an error adding the role.', 'error');
    }
  }

  protected async userRemoveRole(user: User, role: string, site?: AssignedSite) {
    try {
      const updatedUser = await this.userService.api.removeRole({
        id: user.id,
        authentication_token: this.userService.getActiveToken(),
        role,
        site_id: site?.id,
      });
      if (updatedUser) {
        user.roles = updatedUser.roles;
        user.assigned_sites = updatedUser.assigned_sites;
      }
      if (site) {
        this.$genify.notify(
          `${user.name} has had "${this.titleCase(role)}" role removed for "${site.name}"!`,
          'success',
        );
      } else {
        this.$genify.notify(
          `Role "${this.titleCase(role)}" has been removed!`,
          'success',
        );
      }
      return user;
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert('There was an error removing the role.', 'error');
    }
  }

  protected async userTogglePrimaryRole(user: User, site: AssignedSite) {
    if (site?.is_primary) {
      await this.userRemoveRole(user, 'primary_user', site);
    } else {
      await this.userAddRole(user, 'primary_user', site);
    }
  }

  protected highlightExpandIcon(user: User) {
    if (!this.search) return false;

    for (const channel of user.dist_channels) {
      if (Utility.isTextFound(this.search, channel)) return true;
    }

    for (const { name } of user.assigned_sites) {
      if (Utility.isTextFound(this.search, name)) return true;
    }

    return false;
  }

  /**
   * Process event(s) from sibling/child components, like table controls
   * and pagination, etc.
   */
  protected async onAction(action: string) {
    this.logger.info(`Action "${action}" fired`);

    switch (action) {
      case 'download_csv': {
        return this.downloadCsv();
      }
      default: {
        this.logger.warn(
          `Action "${action}" is not implemented in ${this.constructor}`,
        );
      }
    }
  }

  protected async resendInvitation(user: User) {
    this.loading.table = true;
    try {
      const authenticationToken = this.userService.getActiveToken();
      await this.userService.api.sendInvitation({
        authentication_token: authenticationToken,
        order_form_user: {
          email: user.email,
          name: user.name,
        },
      });
      this.$genify.notify(
        `An invitation email has been resent to ${user.email}!`,
        'success',
      );
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert(
        'There was an error resending the invitation. Please try again later.',
        'error',
      );
    } finally {
      this.loading.table = false;
    }
  }

  private async lockUser(user: User) {
    try {
      const locked = await this.userService.api.lock({
        id: user.id,
        authentication_token: this.userService.getActiveToken(),
      });
      if (locked) this.data = this.data.filter((o) => o.id !== user.id);
      this.$genify.notify(`${user.name}'s account has been locked!`, 'success');
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert(`There was an error locking ${user.name}.`, 'error');
    }
  }

  private async unlockUser(user: User) {
    try {
      const unlocked = await this.userService.api.unlock({
        id: user.id,
        authentication_token: this.userService.getActiveToken(),
      });
      if (unlocked) this.data = this.data.filter((o) => o.id !== user.id);
      this.$genify.notify(`${user.name}'s account has been unlocked!`, 'success');
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert(`There was an error locking ${user.name}.`, 'error');
    }
  }

  private async userEdit(user: User) {
    try {
      const updatedUser = await this.userService.api.update({
        id: user.id,
        authentication_token: this.userService.getActiveToken(),
        order_form_user: user,
      });
      if (updatedUser) {
        user = updatedUser;
      }
      this.$genify.notify(`${this.titleCase(user.name)} has been updated!`, 'success');
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert(`There was an error editing ${user.name}.`, 'error');
    }
  }

  private async downloadCsv() {
    this.loading.table = true;
    try {
      const authenticationToken = this.userService.getActiveToken();
      const response = await this.userService.api.download({
        authentication_token: authenticationToken,
      });
      FileSaver.saveAs(
        response,
        `vch_users_list_${moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`,
      );
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert('Error! Failed to download User data.', 'error');
    } finally {
      this.loading.table = false;
    }
  }
}
