var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"root",staticClass:"projected-inventory-interval d-lg-flex flex-row"},[(_vm.isPanel)?_vm._l((_vm.sections),function(section){return _c('info-list-panel',{key:section.label,staticClass:"projected-inventory-interval__table mx-2",staticStyle:{"width":"100%"},attrs:{"section":section}})}):(_vm.isTable)?[_c('v-data-table',{staticClass:"projected-inventory-interval__table",style:({ flex: 1 }),attrs:{"items":_vm.rows,"headers":_vm.headers,"hide-default-footer":"","dense":"","disable-pagination":"","disable-filtering":"","disable-sort":""},scopedSlots:_vm._u([_vm._l((_vm.headers),function(hd,index){return {key:("header." + (hd.value)),fn:function(){return [_c('v-tooltip',{key:index,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({style:({ width: '100%', cursor: 'help', padding: '0.5rem 0' })},'span',attrs,false),on),[_vm._v(_vm._s(hd.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(hd.meta.tooltip))])])]},proxy:true}}),{key:"item.type",fn:function(ref){
var row = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{staticClass:"ma-2",style:({ cursor: 'help' }),attrs:{"small":"","color":row.type === 'CIS' ? 'accent' : 'primary'}},[_vm._v(" "+_vm._s(row.type)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(row.type === 'CIS' ? 'Currently In Stock' : 'Purchase Order'))])])]}},{key:"item.ur",fn:function(ref){
var row = ref.item;
return [_c('span',{class:_vm.numberStyles(row.ur),domProps:{"textContent":_vm._s(row.ur)}})]}},{key:"item.po",fn:function(ref){
var row = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(row.po)+" ")])]}},{key:"item.ao",fn:function(ref){
var row = ref.item;
return [_c('span',{class:_vm.numberStyles(row.ao),domProps:{"textContent":_vm._s(row.ao)}})]}}],null,true)})]:_vm._e(),(_vm.showVisuals)?_c('div',{staticClass:"ma-4 px-4 py-2 faded rounded",style:({ flex: 1 })},[_c('projected-inventory-graph',{attrs:{"interval":_vm.interval}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }