var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"page",staticClass:"projected-inventory"},[_c('div',{ref:"controls",staticClass:"projected-inventory__controls px-0 d-flex justify-space-between"},[_c('div',{staticClass:"projected-inventory__search"},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Search by SKU","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"projected-inventory__start-date"},[_c('v-text-field',{attrs:{"label":"Start Date","readonly":"","disabled":""},model:{value:(_vm.dates[0]),callback:function ($$v) {_vm.$set(_vm.dates, 0, $$v)},expression:"dates[0]"}})],1),_c('projected-inventory-date-picker',{staticClass:"projected-inventory__date-picker",attrs:{"dates":_vm.dates,"disabled":_vm.loading},on:{"update:dates":function($event){_vm.dates=$event},"pick":_vm.onPickDate}}),_c('div',{staticClass:"projected-inventory__seasons"},[_c('v-select',{attrs:{"items":_vm.seasons,"label":"Select Seasons","item-text":"season_name","item-value":"season_code","multiple":"","chips":""},on:{"change":_vm.onChangeCriteria},model:{value:(_vm.selectedSeasons),callback:function ($$v) {_vm.selectedSeasons=$$v},expression:"selectedSeasons"}})],1),_c('v-btn',{staticClass:"export-button",on:{"click":_vm.exportToExcel}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-file-export ")]),_vm._v(" Export to Excel ")],1)],1),_c('v-data-table',{class:[
      'projected-inventory__table',
      (_vm.hasEndDate) ? 'projected-inventory__table--has-end-date' : '' ],attrs:{"loading":_vm.loading,"loading-text":"Loading Report ...","expanded":_vm.expanded,"headers":_vm.getHeaders(),"height":_vm.reportHeight,"items-per-page":_vm.pagination.itemsPerPage,"items":_vm.data,"page":_vm.pagination.page,"search":_vm.search,"server-items-length":_vm.pagination.totalRecords,"item-key":"uid","fixed-header":"","hide-default-footer":"","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
    var interval = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":"100%"}},[_c('projected-inventory-interval',{staticClass:"my-2",attrs:{"display-type":"table","interval":interval}})],1)]}},(_vm.hasEndDate)?{key:"header",fn:function(){return [_c('thead',[_c('tr',[_vm._l((6),function(i){return _c('th',{key:i})}),_c('th',{class:[
              'text-center',
              'background',
              'rounded-tl-lg' ],style:({
              width: '80px',
              borderLeft: ("1px solid " + (_vm.$vuetify.theme.currentTheme.info.toString()) + " !important"),
              borderTop: ("1px solid " + (_vm.$vuetify.theme.currentTheme.info.toString()) + " !important")
            }),attrs:{"colspan":"1"}},[_vm._v(" Start ")]),_c('th',{staticClass:"text-center",style:({
              width: '80px',
              borderTop: ("1px solid " + (_vm.$vuetify.theme.currentTheme.info.toString()) + " !important")
            }),attrs:{"colspan":"1"}},[_vm._v(" End ")])],2)])]},proxy:true}:null,_vm._l((_vm.getHeaders()),function(hd,index){return {key:("header." + (hd.value)),fn:function(){return [_c('v-tooltip',{key:index,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({style:({ width: '100%', cursor: 'help', padding: '1rem 0' })},'span',attrs,false),on),[_vm._v(_vm._s(hd.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(hd.meta.tooltip))])])]},proxy:true}}),{key:"item.id",fn:function(ref){
            var row = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlightSearchText(_vm.search, _vm.getSku(row).id, ['text-no-wrap']))}})]}},{key:"item.color",fn:function(ref){
            var row = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.highlightSearchText(_vm.search, (_vm.getSku(row).color || '--')))}})]}},{key:"item.description",fn:function(ref){
            var row = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.highlightSearchText(_vm.search, (_vm.getSku(row).description || '--')))}})]}},{key:"item.ats",fn:function(ref){
            var row = ref.item;
return [_c('span',{class:_vm.numberStyles(_vm.getAvailableToSell(row)),domProps:{"textContent":_vm._s(_vm.getAvailableToSell(row))}})]}},{key:"item.numPo",fn:function(ref){
            var row = ref.item;
return [_c('span',{class:_vm.numberStyles(_vm.countPos(row)),domProps:{"textContent":_vm._s(_vm.countPos(row))}})]}},{key:"item.ao",fn:function(ref){
            var row = ref.item;
return [_c('span',{class:_vm.numberStyles(row.entries[0].runningTotal),domProps:{"textContent":_vm._s(row.entries[0].runningTotal - row.entries[0].pendingRelease)}})]}},{key:"item.aoEnd",fn:function(ref){
            var row = ref.item;
return [_c('span',{class:_vm.numberStyles(row.entries[row.entries.length - 1].runningTotal),domProps:{"textContent":_vm._s(row.entries[row.entries.length - 1].runningTotal)}})]}},{key:"footer",fn:function(){return [_c('v-data-footer',{attrs:{"options":_vm.pagination,"show-current-page":"","disable-items-per-page":_vm.loading,"disable-pagination":_vm.loading,"show-first-last-page":"","items-per-page-options":[10, 25, 50, 100],"pagination":{
          page: _vm.pagination.page,
          itemsPerPage: _vm.pagination.itemsPerPage,
          pageStart: _vm.pagination.itemsPerPage * (_vm.pagination.page - 1),
          pageStop: _vm.pagination.itemsPerPage * _vm.pagination.page,
          pageCount: Math.ceil(_vm.pagination.totalRecords / _vm.pagination.itemsPerPage),
          itemsLength: _vm.pagination.totalRecords
        }},on:{"update:options":function($event){_vm.pagination=$event}}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }