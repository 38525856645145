import { AttrField, OrmModel, PrimaryKey, HasOneField, BooleanField } from 'vuex-orm-decorators';
import { BaseModel, Auth } from '@/models/internal';

@OrmModel('users')
export class User extends BaseModel {
  /**
   * Model name (used for CASL subject detection)
   */
  public static get modelName() {
    return 'User';
  }

  /**
   * Unique identifier for this User on the API.
   */
  @PrimaryKey()
  @AttrField()
  public id!: number;

  /**
   * Unique email string for the User.
   */
  @AttrField()
  public email!: string;

  /**
   * User's name.
   */
  @AttrField()
  public name!: string;

  /**
   * Date this User was created on the server.
   */
  @AttrField()
  public created_at!: string;

  /**
   * Date this User was updated on the server.
   */
  @AttrField()
  public updated_at!: string;

  /**
   * Date invitation email was sent to User.
   */
  @AttrField()
  public invitation_sent_at!: string;

  /**
   * Invitation acceptance status.
   */
  @BooleanField(false)
  public invitation_accepted!: boolean;

  /**
   * Date invitation was accepted by the User.
   */
  @AttrField()
  public invitation_accepted_at!: string;

  /**
   * ID of User who sent the invitation.
   */
  @AttrField()
  public invited_by_id!: number;

  /**
   * User who sent the invitation.
   */
  @HasOneField(User, 'invited_by_id', 'id')
  public invited_by!: User;

  /**
   * User's avatar
   */
  @AttrField()
  public avatar?: string;

  /**
   * List of roles this User has.
   */
  @AttrField([])
  public roles!: Array<string>;

  /**
   * List of distribution channels the User is associated with
   */
  @AttrField([])
  public dist_channels!: Array<string>;

  /**
   * List of sites the User is assigned to (applicable for site_user roles)
   */
  @AttrField([])
  public assigned_sites!: Array<AssignedSite>;

  /**
   * User account's lock status
   */
  @BooleanField(false)
  public access_locked!: boolean;

  /**
   * User account's on call status (for admins)
   */
  @BooleanField(false)
  public is_on_call!: boolean;

  /**
   * Auth association
   */
  @HasOneField(Auth, 'user_id', 'id')
  public auth?: Auth;

  /**
   * User's type
   */
  @AttrField()
  public type?: string;

  public order_form_user_id: any;

  /**
   * Gets associated Auth object, if not already attached
   * to this instance, and returns it's `authentication_token`
   * field.
   */
  public get authentication_token() {
    if (this.auth?.authentication_token) {
      return this.auth.authentication_token;
    }
    const user = User
      .query()
      .with('auth')
      .whereId(this.id)
      .first();
    this.auth = user?.auth;
    return user?.auth?.authentication_token ?? '';
  }

  /**
   * Gets associated Auth object, if not already attached
   * to this instance, and returns it's `permissions` data.
   */
  public get permissions() {
    if (this.auth?.permissions) {
      return this.auth.permissions;
    }
    const user = User
      .query()
      .with('auth')
      .whereId(this.id)
      .first();
    this.auth = user?.auth;
    return user?.auth?.permissions ?? '';
  }
}

export default User;

/**
 * Site assigned to the User
 */
export type AssignedSite = {
  id: string;
  name: string;
  is_primary: boolean;
};
