import { ModelService, AuthService } from '.';
import { Item } from '@/lib/types';
import { User } from '@/models/internal';
import { IApiService, ICreateArguments, IFindArguments, IFindOneArguments, IRequestArguments } from '@/lib/interfaces';
import { ResponseType } from 'axios';

export class UserService extends ModelService<typeof User> {
  /**
   * Cached instance of the service
   */
  private static instance: UserService | null = null;

  protected model = User;

  protected path = '/order_form_users';

  private readonly authService: AuthService = AuthService.getInstance();

  /**
   * Constructor
   */
  private constructor() {
    super();
  }

  /**
   * Get an instance of the UserService
   */
  public static getInstance() {
    if (!this.instance) {
      this.instance = new UserService();
      return this.instance;
    }
    return this.instance;
  }

  /**
   * Get the active User
   */
  public getActive(): Item<User> {
    return this.model
      .query()
      .with('auth')
      .whereId(User.active)
      .first();
  }

  /**
   * Get the active User
   */
  public getActiveToken(): string {
    const activeToken = this.authService.getActive();
    if (!activeToken) {
      throw Error('Unable to get an authentication token.');
    }
    return activeToken.authentication_token;
  }

  /**
   * Set the active User
   */
  public setActive(record: User | string | number | null): Item<User> {
    return this.model.setActive(record);
  }

  /**
   * Get this User's associated Auth record or return null if not found
   * @param user
   */
  public getAuth(user: User) {
    return this.authService
      .query()
      .where('user_id', user.id)
      .first();
  }

  public get api(): IApiService {
    return {
      /**
       * Find a list of Users on the server via GET request
       */
      find: async (args: IUserFindArguments) => {
        const { data } = await this.apiService.get(this.path, args);
        return data;
      },

      /**
       * Create a User on the server via POST request
       */
      create: async () => {
        throw Error('This method has no implementation yet');
      },

      /**
       * Find one User on the server via GET request
       */
      findOne: async () => {
        throw Error('This method has no implementation yet');
      },

      /**
       * Update an existing User on the server via PATCH request
       */
      update: async (args) => {
        const { data } = await this.apiService.patch(`${this.path}/${args.id}`, args);
        return data;
      },

      /**
       * Delete an existing User on the server via DELETE request
       */
      destroy: async () => {
        throw Error('This method has no implementation yet');
      },

      /**
       * Update an existing User's "on call" role on the server via POST request
       */
      onCall: async (args: IUserOnCallArguments) => {
        const { data } = await this.apiService.post(`${this.path}/${args.id}/on_call`, args);
        return data;
      },

      /**
       * Locks the User
       */
      lock: async (args: IFindOneArguments) => {
        const { data } = await this.apiService.patch(`${this.path}/${args.id}/lock`, args);
        return data;
      },

      /**
       * Unlocks the User
       */
      unlock: async (args: IFindOneArguments) => {
        const { data } = await this.apiService.patch(`${this.path}/${args.id}/unlock`, args);
        return data;
      },

      /**
       * Adds distribution channel assignment for the User
       */
      addDistChannel: async (args: IUserDistChannelArguments) => {
        const { data } = await this.apiService.post(`${this.path}/${args.id}/dist_channel`, args);
        return data;
      },

      /**
       * Removes distribution channel assignment for the User
       */
      removeDistChannel: async (args: IUserDistChannelArguments) => {
        const { data } = await this.apiService.delete(`${this.path}/${args.id}/dist_channel`, args);
        return data;
      },

      /**
       * Adds site request assignment for the User
       */
      addSiteRequest: async (args: IUserSiteRequestArguments) => {
        const { data } = await this.apiService.post(`${this.path}/${args.id}/site_request`, args);
        return data;
      },

      /**
       * Removes site request assignment for the User
       */
      removeSiteRequest: async (args: IUserSiteRequestArguments) => {
        const { data } = await this.apiService.delete(`${this.path}/${args.id}/site_request`, args);
        return data;
      },

      /**
       * Adds role for the User
       */
      addRole: async (args: IUserRoleArguments) => {
        const { data } = await this.apiService.post(`${this.path}/${args.id}/role`, args);
        return data;
      },

      /**
       * Removes role for the User
       */
      removeRole: async (args: IUserRoleArguments) => {
        const { data } = await this.apiService.delete(`${this.path}/${args.id}/role`, args);
        return data;
      },

      /**
       * Download CSV data listing Users
       */
      download: async (args: IDownloadUsersArguments) => {
        const { data } = await this.apiService.get(`${this.path}/download`, args, {
          responseType: args.response_type ?? 'blob',
        });
        return data;
      },

      /**
       * Send invitation
       */
      sendInvitation: async (args: ISendInvitationArguments) => this.apiService.post(`${this.path}/invitation`, args),

      /**
       * Accept invitation
       */
      acceptInvitation: async (args: IAcceptInvitationArugments) => this.apiService.patch(`${this.path}/invitation`, args),

      /**
       * Get user's brands and authentication details for each brand
       */
      getBrands: async (args: IFindOneArguments) => {
        const { data } = await this.apiService.get(`${this.path}/${args.id}/brands`, args);
        return data;
      },

    };
  }
}

interface IUserFindArguments extends IFindArguments {
  // Show additional User fields
  show_details?: boolean;
}

interface IUserOnCallArguments extends IFindOneArguments {
  is_on_call: boolean;
}

interface IUserDistChannelArguments extends IFindOneArguments {
  dist_channel: string;
}
interface IUserSiteRequestArguments extends IFindOneArguments {
  site_id: string;
}

interface IUserRoleArguments extends IFindOneArguments {
  role: string;
}

/**
 * Download Users CSV arguments
 */
interface IDownloadUsersArguments extends IFindArguments {
  /**
   * Response type header
   */
  response_type?: ResponseType;
}

export interface ISendInvitationArguments extends ICreateArguments {
  order_form_user: {
    name: string;
    email: string;
    roles?: string[];
    dist_channels?: string[];
    assigned_sites?: string[];
  };
  invitation_message?: string;
}

export interface IAcceptInvitationArugments extends IRequestArguments {
  access_token: string;
  order_form_user: {
    invitation_token: string;
    password: string;
    password_confirmation: string;
  };
}
