import { BaseModel } from '@/models/internal';
import { AttrField, OrmModel, PrimaryKey } from 'vuex-orm-decorators';

@OrmModel('brands')
export class Brand extends BaseModel {
  /**
   * Model name (used for CASL subject detection)
   */
  public static get modelName() {
    return 'Brand';
  }

  @PrimaryKey()
  @AttrField()
  public id!: number;

  @AttrField()
  public name!: string;

  @AttrField()
  public admin_email!: string;

  @AttrField()
  public access_token!: string;

  @AttrField()
  public frontend_domain!: string;

  @AttrField()
  public status!: string;

  @AttrField()
  public order_form_endpoint_user: any;
}

export default Brand;
