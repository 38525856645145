var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users-list__wrapper"},[_c('users-list-controls',{attrs:{"loading":_vm.loading.table,"search":_vm.search,"show-locked":_vm.showLocked},on:{"update:search":function($event){_vm.search=$event},"update:showLocked":function($event){_vm.showLocked=$event},"update:show-locked":function($event){_vm.showLocked=$event}}}),_c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading.table),expression:"loading.table"}],attrs:{"type":"table-tbody","width":"100%","height":"100%"}}),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading.table),expression:"!loading.table"}],staticClass:"users-list__table",attrs:{"headers":_vm.headers,"items":_vm.data,"server-items-length":_vm.totalRecords,"items-per-page":_vm.options.itemsPerPage,"page":_vm.options.page,"sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"search":_vm.search,"item-key":"id","show-expand":"","hide-default-footer":""},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:page":function($event){return _vm.$set(_vm.options, "page", $event)},"update:sortBy":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var user = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(!user.roles.includes('admin') && !user.roles.includes('api'))?[(isExpanded)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":_vm.highlightExpandIcon(user) ? 'primary lighten-5' : undefined,"fab":"","text":"","depressed":"","small":""},on:{"click":function($event){return expand(false)}}},on),[_c('v-icon',[_vm._v("mdi-chevron-up")])],1)]}}],null,true)},[_c('span',[_vm._v("Hide User details")])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":_vm.highlightExpandIcon(user) ? 'primary lighten-5' : undefined,"fab":"","depressed":"","small":""},on:{"click":expand}},on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('span',[_vm._v("Show User details")])])]:_vm._e()]}},{key:"expanded-item",fn:function(ref){
var user = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length + 1}},[_c('users-list-details',{attrs:{"user":user,"search":_vm.search},on:{"add-dist-channel":_vm.userAddDistChannel,"remove-dist-channel":_vm.userRemoveDistChannel,"add-site":_vm.userAddSiteRequest,"remove-site":_vm.userRemoveSiteRequest,"add-role":_vm.userAddRole,"remove-role":_vm.userRemoveRole,"edit-user":_vm.userEdit,"toggle-primary-user":_vm.userTogglePrimaryRole}})],1)]}},{key:"item.name",fn:function(ref){
var name = ref.value;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlightSearchText(_vm.search, name))}})]}},{key:"item.email",fn:function(ref){
var email = ref.value;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlightSearchText(_vm.search, email))}})]}},{key:"item.created_at",fn:function(ref){
var created_at = ref.value;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlightSearchText(_vm.search, _vm.formatDate(created_at, true)))}})]}},{key:"item.roles",fn:function(ref){
var roles = ref.value;
return [_c('div',{staticClass:"users-list__roles my-1"},_vm._l((roles),function(role){return _c('users-list-role',{key:role,staticClass:"users-list__role my-1",attrs:{"role":role}})}),1)]}},{key:"item.confirmed",fn:function(ref){
var confirmed = ref.value;
return [(confirmed)?_c('v-icon',[_vm._v(" mdi-check ")]):_vm._e()]}},{key:"item.invited",fn:function(ref){
var user = ref.item;
return [(user.invitation_accepted)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-check ")])]}}],null,true)},[_c('div',[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Accepted ")]),_c('p',[_vm._v("Sent by: "+_vm._s(user.invited_by.name))]),_c('p',[_vm._v(" Accepted at: "+_vm._s(_vm.formatDate(user.invitation_accepted_at, true))+" ")])])]):(user.invitation_sent_at)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","depressed":"","x-small":"","color":"warning"},on:{"click":function($event){return _vm.resendInvitation(user)}}},on),[_c('v-icon',[_vm._v(" mdi-email-send ")])],1)]}}],null,true)},[_c('div',[_c('p',[_vm._v(" Resend "),_c('span',{staticClass:"font-italic"},[_vm._v(" (Pending) ")])]),_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v("Sent by: "+_vm._s(user.invited_by.name))]),_c('span',[_vm._v("Sent at: "+_vm._s(_vm.formatDate(user.invitation_sent_at, true)))])])])]):_c('v-icon',{attrs:{"color":"grey lighten-2"}},[_vm._v(" mdi-minus ")])]}},{key:"item.access_locked",fn:function(ref){
var user = ref.item;
var access_locked = ref.value;
return [(!user.roles.includes('admin') && !user.roles.includes('api'))?[(access_locked)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var hover = ref.value;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","small":""},on:{"click":function($event){return _vm.onClickUnlock(user)}}},on),[_c('v-fade-transition',{attrs:{"group":"","leave-absolute":""}},[(hover)?_c('v-icon',{key:"unlock",attrs:{"color":"success"}},[_vm._v(" mdi-lock-open-variant ")]):_c('v-icon',{key:"lock",attrs:{"color":"grey lighten-1"}},[_vm._v(" mdi-lock ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Unlock user")])]):_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var hover = ref.value;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","small":""},on:{"click":function($event){return _vm.onClickLock(user)}}},on),[_c('v-fade-transition',{attrs:{"group":"","leave-absolute":""}},[(hover)?_c('v-icon',{key:"lock",attrs:{"color":"error"}},[_vm._v(" mdi-lock ")]):_c('v-icon',{key:"unlock",attrs:{"color":"grey lighten-1"}},[_vm._v(" mdi-lock-open-variant ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Lock user")])])]:_vm._e()]}},{key:"footer",fn:function(){return [_c('v-data-footer',{attrs:{"options":_vm.options,"show-current-page":"","show-first-last-page":"","items-per-page-options":[5, 10, 25, 50],"pagination":{
          page: _vm.options.page,
          itemsPerPage: _vm.options.itemsPerPage,
          pageStart: _vm.options.itemsPerPage * (_vm.options.page - 1),
          pageStop: _vm.options.itemsPerPage * _vm.options.page,
          pageCount: Math.ceil(_vm.totalRecords / _vm.options.itemsPerPage),
          itemsLength: _vm.totalRecords,
        }},on:{"update:options":function($event){_vm.options=$event}}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }