
















































import { Vue, Component, Ref, Emit, Prop } from 'vue-property-decorator';
import { VMenu } from 'vuetify/lib';

@Component({
  name: 'ProjectedInventoryDatePicker',
})
export class ProjectedInventoryDatePicker extends Vue {
  @Prop({ required: false, default: new Date().toISOString().substr(0, 10) })
  protected minimumDate!: string;

  @Ref('menu')
  protected readonly menuRef!: typeof VMenu;

  // Store only the end date (the start date is always today)
  protected endDate = '';

  protected menu =  false;

  protected active = false;

  // Set start date to today's date
  protected get startDate(): string {
    return new Date().toISOString().substr(0, 10);
  }

  // Concatenate the start date (today) with the selected end date for display
  protected get dateRangeText() {
    return (this.endDate ? this.endDate : 'Select an end date');
  }

  protected get validDates(): boolean {
    return !!this.endDate; // Ensure that an end date is selected
  }

  @Emit('pick')
  protected onConfirm() {
    // Emit today's start date and the selected end date
    const dateRange = [this.startDate, this.endDate];
    (this.menuRef as any).save(this.endDate);
    return dateRange;
  }
}

export default ProjectedInventoryDatePicker;
