import { ModelService } from './ModelService';
import { Brand } from '@/models/internal';
import { IFindArguments, IFindOneArguments, IUpdateArguments } from '@/lib/interfaces';
import { UserService } from '@/services/UserService';

/**
 * Service class for interacting with the Brand model
 */
export class BrandService extends ModelService<typeof Brand> {
  /**
   * Cached instance of the service
   */
  private static instance: BrandService | null = null;

  protected model = Brand;

  protected path = '/brands';

  private userService: UserService = UserService.getInstance();

  /**
   * Constructor
   */
  private constructor() {
    super();
  }

  /**
   * Get an instance of the CustomerService
   */
  public static getInstance() {
    if (!this.instance) {
      this.instance = new BrandService();
      return this.instance;
    }
    return this.instance;
  }

  public get api() {
    return {
      /**
       * Create a Brand on the server via POST request
       */
      create: async () => {
        throw Error('This method has no implementation yet');
      },

      /**
       * Find one Brand on the server via GET request
       */
      findOne: async (args: IFindOneArguments) => {
        const { data } = await this.apiService.get(`${this.path}/${args.id}`, args);
        return data;
      },

      /**
       * Find a list of Brands on the server via GET request
       */
      find: async (args: IBrandFindArguments): Promise<any> => {
        const { data } = await this.apiService.get(this.path, args);
        return data;
      },

      /**
       * Update an existing Brand on the server via PUT request
       */
      update: async (args: IBrandUpdateArguments) => {
        const { data } = await this.apiService.patch(`${this.path}/${args.id}`, args);
        return data;
      },

      /**
       * Delete an existing Brand on the server via DELETE request
       */
      destroy: async () => {
        throw Error('This method has no implementation yet');
      },

      /**
       * Fetches brands with connection status for the current user
       */
      fetchBrandsWithStatus: async (): Promise<Brand[]> => {
        const user = this.userService.getActive();
        const activeToken = this.userService.getActiveToken();

        const params = {
          id: user && user.id,
          authentication_token: activeToken,
        };

        const brandsResponse = await this.api.find(params);
        const usersBrandsResponse = await this.userService.api.getBrands(params);

        if (!brandsResponse) throw Error('Unable to fetch a list of brands');

        const connectedBrandIds = new Set(usersBrandsResponse.brands.map((brand: any) => brand.id));

        return brandsResponse.brands.map((brand: any) => {
          const endpointUser = brand && brand.order_form_endpoint_user;

          return {
            ...brand,
            status:
            endpointUser && endpointUser.authentication_token === activeToken
              ? 'active'
              : connectedBrandIds.has(brand.id)
                ? 'connected'
                : '',
          };
        });
      },
    };
  }
}

interface IBrandFindArguments extends IFindArguments {
  page?: number | string;
  per_page?: number | string;
  title_containing?: string;
}

interface IBrandUpdateArguments extends IUpdateArguments {
  brand: {
    meta: Record<string, any>;
  };
}
