import { Model } from '@vuex-orm/core';
import { Config } from '@/lib/types';
import moment, { Moment } from 'moment';

export class Utility {
  private static config: (Config | null) = null;

  /**
   * Fetch configuration file from filesystem (created on deployment)
   * and cache it in the class as `config`
   */
  public static async fetchConfig(): Promise<Config> {
    if (this.config) return this.config;
    const config: Config = await fetch(`${window.location.origin}/config.json`)
      .then((res) => res.json());
    this.config = config;
    this.config.portal.access_token = process.env.VUE_APP_ORDER_FORM_ENDPOINT ?? '';
    return this.config;
  }

  /**
   * Fetch commit hash of the application in it's deployed state
   */
  public static async fetchVersion(): Promise<string> {
    const commitJson = await fetch(`${window.location.origin}/.commit`).then((res) => res.json());
    const version = commitJson.commit;
    return version;
  }

  /**
   * Convert a string to title-case.
   * @param input String to convert
   */
  public static titleCase(input: string): string {
    if (!input) return '';
    const pieces = [...input.toLowerCase().split(/[\s_-]/)];
    for (let i = 0; i < pieces.length; i++) {
      pieces[i] = pieces[i].charAt(0).toUpperCase() + pieces[i].slice(1);
    }
    return pieces.join(' ');
  }

  /**
   * Format a phone number using regular expressions
   * @param phone
   */
  public static formatPhone(phone: string): string {
    if (!phone) return '';
    const regExp = /^(\d{3})(?:-|\s)?(\d{3})(?:-|\s)?(\d{4})(?:\s)?(E?XT?\s?\d{1,})?$/;
    const m = phone.match(regExp);
    if (m) {
      let base = `(${m[1]}) ${m[2]}-${m[3]}`;
      if (m[4]) base += ` [EXT. ${m[4].replace(/\D/g, '')}]`;
      return base;
    }
    return phone;
  }

  /**
   * Format a date string, or return null if the date was invalid.
   * @param date
   * @param withTime Format the date with time. Optional.
   */
  public static formatDate(date: string | Moment, withTime = false): string {
    const d = moment(date);
    if (withTime) return d.format('YYYY-MM-DD @ hh:mma');
    return d.format('YYYY-MM-DD');
  }

  /**
   * Copy properties of one object to matching properties
   * of another object. Returns `true` if copy was sucessful
   * and `false` if not.
   * @param a Copy from
   * @param b Copy to
   */
  public static copyProperties(a: Record<string, any>, b: Record<string, any>): boolean {
    if (!a || !b) false;
    Object.keys(a).forEach((key) => {
      if (typeof b[key] === 'undefined') return;
      b[key] = a[key];
    });
    return true;
  }

  /**
   * Checks if the current browser is IE
   */
  public static isUsingIEBrowser() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    if (msie > -1 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
      return true;
    }
    return false;
  }

  /**
   * Format a price or amount of money to specific currency/locale.
   * @param {Number} amount - Price or amount to be formatted
   * @param {String} [currency] - Type of currency (ex. USD, CAD, EUR, etc.)
   * @param {String} [locale] - Locale format
   * @returns {String} Formatted amount
   */
  public static formatCurrency(amount: any, currency?: any, locale?: any) {
    return new Intl.NumberFormat(
      locale || 'en',
      {
        style: 'currency',
        currency: currency || 'CAD',
      },
    ).format(amount);
  }

  /**
   * Look for and split a comma-delimited list of emails,
   * returning just one if no commas are found
   *
   * NOTE: This is merely for handling inconsistencies in
   * email formats coming from Blastramp
   *
   * @param {String} [email] - Email address
   *
   * @returns {String} Formatted email address
   */
  public static formatEmail(email: string) {
    return email
      .split(',')
      .map((e: string) => `<div>${e.trim().toLocaleLowerCase()}</div><br />`)
      .join('\n');
  }

  /**
   * Search for text in a string and replace with HTML for highlighting it
   * in the browser.
   *
   * @param {String} needle - Search string
   * @param {String} haystack - Target string to search against
   *
   * @returns {String} Reformatted HTML string highlighting search
   */
  public static highlightSearchText(needle: string, haystack: string, classList: string[] = []) {
    const wrap = (str: string) => `<span class="${classList.join(' ')}">${str}</span>`;
    if (!needle) return wrap(haystack);
    if (typeof haystack !== 'string') haystack = String(haystack);

    classList = classList.map((c) => typeof c === 'string' ? c : String(c));

    needle.split('+').forEach((piece) => {
      if (!piece) return;
      const regExp = new RegExp(`(\\s*)(${piece.trim().replace('*', '')})(\\s*)`, 'gi');
      haystack = haystack.replace(regExp, '$1<span class="search-text-highlight">$2</span>$3');
    });

    return wrap(haystack);
  }

  /**
   * Checks for existence of search text in a string.
   *
   * @param {String} needle - Search string
   * @param {String} haystack - Target string to search against
   *
   * @returns {Boolean} True if text is found; false otherwise.
   */
  public static isTextFound(needle: string, haystack: string) {
    if (!needle) return false;
    if (typeof haystack !== 'string') haystack = String(haystack);
    for (const piece of needle.split('+')) {
      if (!piece) continue;
      const regExp = new RegExp(`${piece.trim().replace('*', '')}`, 'gi');
      if (~haystack.search(regExp)) return true;
    }
    return false;
  }

  /**
   * Detect if client has an adblocker activated by making requests to
   * URLs our application depends on.
   * @returns client has adblocking enabled?
   */
  public static async hasAdBlock(): Promise<boolean> {
    let blocked = false;

    const config: RequestInit = { method: 'HEAD', mode: 'no-cors' };
    const critical = ['https://sentry.io'];

    try {
      await Promise.all(critical.map((url) => fetch(url, config)));
      blocked = false;
    } catch (error) {
      blocked = true;
    }

    return blocked;
  }

  /**
   * Clears the Vuex ORM cache for the specified models and optionally reloads the page.
   *
   * @param models Array of Vuex ORM models to clear
   * @param reload Whether to reload the page after clearing (default: false)
   */
  public static async clearCache(models: typeof Model[], reload = false): Promise<void> {
    try {
      await Promise.all(models.map((model) => model.deleteAll()));

      if (reload) {
        location.reload();
      }
    } catch (error) {
      throw new Error('An error occurred while clearing the cache.');
    }
  }
}

export default Utility;
