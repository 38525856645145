import { Order, Customer, User, Collection, Product, Brand } from '@/models/internal';
import { Ability, AbilityClass } from '@casl/ability';

export type OrderAbilities = [
  | 'create'
  | 'read'
  | 'update'
  | 'manage'
  | 'manage_part_a'
  | 'manage_part_b'
  | 'manage_part_c'
  | 'review'
  | 'approve'
  | 'approve_part_a'
  | 'approve_part_b'
  | 'approve_part_c',
  Order | typeof Order
];

export type CollectionAbilities = [
  | 'create'
  | 'read'
  | 'update'
  | 'destroy'
  | 'manage',
  Collection | typeof Collection
];

export type ProductAbilities = [
  | 'create'
  | 'read'
  | 'update'
  | 'destroy'
  | 'manage',
  Product | typeof Product
];

export type CustomerAbilities = [
  | 'create'
  | 'read'
  | 'update'
  | 'manage'
  | 'approve',
  Customer | typeof Customer
];

export type UserAbilities = [
  | 'manage'
  | 'order_for',
  User | typeof User
];

export type ReportAbilities = [
  | 'create'
  | 'read'
  | 'update'
  | 'manage',
  'Report'
];

export type BrandAbilities = [
  | 'create'
  | 'read'
  | 'update'
  | 'manage'
  | 'approve',
  Brand | typeof Brand
];

export type AllAbilities = [
  | 'create'
  | 'read'
  | 'update'
  | 'manage'
  | 'approve',
  'all'
];

export type AppAbilities =
  | OrderAbilities
  | CustomerAbilities
  | CollectionAbilities
  | UserAbilities
  | ReportAbilities
  | ProductAbilities
  | BrandAbilities
  | AllAbilities;

export type AppAbility = Ability<AppAbilities>;
export const AppAbility = Ability as AbilityClass<AppAbility>;
