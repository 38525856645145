import { AppAbilities } from '@/lib/types';
import { Collection, Customer, Product, User, Brand } from '@/models/internal';
import { Location } from 'vue-router';

export type NavigationLink = {
  title: string;
  action: string;
  to: Location | null;
  access?: AppAbilities;
  group?: string;
  items?: Omit<NavigationLink, 'group'>[];
  divider?: boolean;
};

const links: NavigationLink[] = [
  {
    title: 'Home',
    action: 'mdi-home',
    to: { name: 'home' },
    access: ['read', 'Report'],
  },
  {
    title: 'Reports',
    action: 'mdi-chart-bar',
    to: null,
    group: 'dashboard/reports',
    access: ['read', 'Report'],
    items: [
      {
        title: 'Order Summary',
        to: { name: 'order-summary' },
        action: 'mdi-table',
      },
      {
        title: 'EOC PPE Supply',
        to: { name: 'eoc-ppe-supply' },
        action: 'mdi-table',
      },
      {
        title: 'PPE Supply By Category',
        to: { name: 'ppe-supply-by-category' },
        action: 'mdi-table',
      },
      {
        title: 'PPE Supply By Sku',
        to: { name: 'ppe-supply-by-sku' },
        action: 'mdi-table',
      },
      {
        title: 'PHSA Order Calculation',
        to: { name: 'phsa-order-calculation' },
        action: 'mdi-table',
      },
      {
        title: 'REOC Order Report',
        to: { name: 'reoc-order-report' },
        action: 'mdi-table',
      },
      {
        title: 'REOC PPE Supply Report',
        to: { name: 'reoc-ppe-supply-report' },
        action: 'mdi-table',
      },
    ],
  },
  {
    title: 'Brands',
    action: 'mdi-star-circle-outline',
    to: null,
    group: 'dashboard/customers',
    access: ['manage', Brand],
    items: [
      {
        title: 'View Brands',
        to: { name: 'brands-list' },
        action: 'mdi-format-list-bulleted-square',
      },
    ],
  },
  {
    title: 'Sites',
    action: 'mdi-map-marker-multiple',
    to: null,
    group: 'dashboard/customers',
    access: ['read', Customer],
    items: [
      {
        title: 'View Sites',
        to: { name: 'customers-list' },
        action: 'mdi-format-list-bulleted-square',
      },
      // {
      //   title: 'Create Site',
      //   to: { name: 'customers-create' },
      //   action: 'mdi-map-marker-plus',
      // },
    ],
  },
  {
    title: 'Orders',
    to: null,
    group: 'dashboard/orders',
    action: 'mdi-cart',
    items: [
      {
        title: 'View Orders',
        to: { name: 'orders-list' },
        action: 'mdi-format-list-bulleted-square',
      },
      {
        title: 'Create Order',
        to: { name: 'orders-setup' },
        action: 'mdi-cart-plus',
      },
    ],
  },
  {
    title: 'Collections',
    to: null,
    group: 'dashboard/collections',
    action: 'mdi-view-grid',
    access: ['manage', Collection],
    items: [
      {
        title: 'View Collections',
        to: { name: 'collections-list' },
        action: 'mdi-format-list-bulleted-square',
      },
      // {
      //   title: 'Create Collection',
      //   to: { name: 'collections-create' },
      //   action: 'mdi-view-grid-plus',
      // },
    ],
  },
  {
    title: 'Products',
    to: null,
    group: 'dashboard/products',
    action: 'mdi-tag',
    access: ['manage', Product],
    items: [
      {
        title: 'View Products',
        to: { name: 'products-list' },
        action: 'mdi-format-list-bulleted-square',
      },
    ],
  },
  {
    title: 'Users',
    to: null,
    group: 'dashboard/users',
    action: 'mdi-account-supervisor',
    access: ['manage', User],
    items: [
      {
        title: 'View Users',
        to: { name: 'users-list' },
        action: 'mdi-format-list-bulleted-square',
      },
      {
        title: 'Directors On Call',
        to: { name: 'directors-on-call-list' },
        action: 'mdi-card-account-phone-outline',
      },
    ],
  },
  {
    title: 'Settings',
    action: 'mdi-cog',
    to: { name: 'settings' },
    divider: true,
  },
  {
    title: 'Help',
    action: 'mdi-help-circle',
    to: null,
    group: 'dashboard/docs',
    items: [
      {
        title: 'User Manual',
        action: 'mdi-account-question',
        to: {
          name: 'docs-md',
          params: { doc: 'user-manual' },
        },
      },
    ],
  },
];

export default links;
