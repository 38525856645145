















































// import Vue from 'vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Logger } from '@/tools/Logger';

@Component({
  name: 'BrandsCustomersView',
})

export default class BrandsCustomersView extends Vue {
  @Prop({ type: Boolean, required: true }) public readonly value!: boolean;

  public data: ICustomer[] = [];

  public selected: ICustomer[] = [];

  protected readonly logger: Logger = new Logger({ context: 'BrandsCustomersList' });

  public get drawer() {
    return this.value;
  }

  public set drawer(val: boolean) {
    this.$emit('input', val); // Emit input event for v-model
  }

  public async init() {
    try {
      this.data = await this.fetchData();
    } catch (error) {
      this.logger.error(error);
    }
  }

  public created() {
    this.init();
  }

  public async fetchData() {
    // TODO: Make this dynamic once the endpoint is available
    return [
      { id: 1, name: 'Site 1' },
      { id: 2, name: 'Site 2' },
      { id: 3, name: 'Site 3' },
      { id: 4, name: 'Site 4' },
      { id: 5, name: 'Site 5' },
      { id: 6, name: 'Site 6' },
    ];
  }

  public submitSelectedSites() {
    let selectedSites = this.selected;

    // submit selected sites to api endpoint
    // TODO: submit this data to the appropriate api endpoint once
    // it's available
    let response = 'api endpoint code here for ' + selectedSites;

    // return response from api
    return response;
  }
}

interface ICustomer {
  id: number;
  name: string;
}

