





























































































import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

@Component({
  name: 'BrandCard',
})

export default class BrandCard extends Vue {
  @Prop({ type: String, default: '' }) public readonly access_token!: string;

  @Prop({ type: String, required: true }) public readonly name!: string;

  @Prop({ type: String, default: '' }) public readonly contact!: string;

  @Prop({ type: String, default: '' }) public readonly admin_email!: string;

  @Prop({ type: String, default: '' }) public readonly logo!: string;

  @Prop({ type: String, default: '' }) public readonly status!: string;

  public get processedName(): string {
    return this.name.replace(/\b(Order|Form)\b/g, '').trim();
  }

  public openSiteSelectDrawer(): void {
    this.$emit('open-drawer', this.$props.access_token); // Pass access_token if needed
  }
}
