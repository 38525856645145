














































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { SentryService } from '@/services/SentryService';
import { Get, Sync } from 'vuex-pathify';
import MainLayout from '@/layouts/MainLayout.vue';
import FullScreenLayout from '@/layouts/FullScreenLayout.vue';
import { UserService } from './services';
import { AdBlockBanner } from '@/components/AdBlockBanner/AdBlockBanner.vue';
// import { DelayBanner } from '@/components/DelayBanner/DelayBanner.vue';

@Component({
  name: 'App',
  components: {
    MainLayout,
    FullScreenLayout,
    AdBlockBanner,
    // DelayBanner,
  },
})
export default class App extends Vue {
  @Get('context/theme@dark')
  protected isDarkTheme!: boolean;

  @Sync('context/overlay@visible')
  protected isOverlayVisible!: boolean;

  @Sync('layout/totalHeight')
  protected totalHeight!: number;

  @Sync('layout/name')
  protected layout!: string;

  @Get('ui/global@loading')
  protected uiLoading!: boolean;

  protected drawer = null;

  protected year = new Date().getFullYear();

  protected refreshing = false;

  protected registration: any = null;

  protected updateExists = false;

  private readonly userService = UserService.getInstance();

  private readonly sentryService = SentryService.getInstance();

  @Watch('isDarkTheme')
  protected onThemeChange() {
    this.applyTheme();
  }

  protected get theme() {
    return (this.isDarkTheme) ? 'dark' : 'light';
  }

  public created() {
    this.isOverlayVisible = false;
    this.applyTheme();

    document.addEventListener('swUpdated', this.updateAvailable, { once: true });

    // Prevent multiple refreshes
    if (navigator?.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        // Here the actual reload of the page occurs
        window.location.reload();
      });
    }
  }

  public mounted() {
    // Configure information about the current user if one is
    // logged in. This provides us with a lot of context about
    // the user when an error occurs.
    const user = this.userService.getActive();
    if (user) this.sentryService.configureUser(user);

    window.addEventListener('resize', this.onResize);
    this.$nextTick(() => {
      this.onResize();
    });
  }

  public destroyed() {
    window.removeEventListener('resize', this.onResize);
  }

  protected applyTheme() {
    this.$vuetify.theme.dark = this.isDarkTheme;
  }

  protected onResize() {
    this.totalHeight = window.innerHeight;
  }

  protected updateAvailable(event: any) {
    this.registration = event.detail;
    this.updateExists = true;
  }

  // Called when the user accepts the update
  protected refreshApp() {
    this.updateExists = false;
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!this.registration || !this.registration.waiting) return;
    // send message to SW to skip the waiting and activate the new SW
    this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  }
}
