



















































































import { Collection, Comment, Customer, Fulfillment, Order, OrderItem, Product } from '@/models/internal';
import { Vue, Component } from 'vue-property-decorator';
import { Sync } from 'vuex-pathify';
import { Utility } from '@/tools/Utility';
import { Page } from '@/components/Page.vue';

@Component({
  name: 'Settings',
  components: {
    Page,
  },
})
export default class Settings extends Vue {
  @Sync('context/theme@dark')
  protected isDarkTheme!: boolean;

  protected loading = false;

  protected version: string | null = null;

  public created() {
    this.init();
  }

  /**
   * Clear cached ORM data
   */
  protected async onClearCache() {
    await Utility.clearCache(
      [
        Collection,
        Comment,
        Customer,
        Fulfillment,
        Order,
        OrderItem,
        Product,
      ],
      true, // Pass `true` to reload the page after clearing
    );
  }

  private async init() {
    this.loading = true;
    this.version = await Utility.fetchVersion();
    this.loading = false;
  }
}
